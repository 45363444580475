<template>
  <div>
    <header data-role="Header" class="whiteboard-custom-nav-header bar">
      <img alt="logo" src="/playground_assets/inforit-1500h.png-1500h.webp" class="whiteboard-custom-nav-image" />
      <div class="whiteboard-custom-nav-btn-group">
        <!-- <a @click="showOverlay = true"
          class="whiteboard-custom-nav-link"
        >
          Forgot Password
      </a> -->
        <a href="mailto:contact@inforit.at?subject=Problem Whiteboard" rel="noreferrer noopener"
          class="whiteboard-custom-nav-link1">
          Problem melden
        </a>
        <div data-thq="thq-dropdown" class="whiteboard-custom-nav-thq-dropdown list-item">
          <div data-thq="thq-dropdown-toggle" class="whiteboard-custom-nav-dropdown-toggle">
            <a href="mailto:contact@inforit.at?subject=Problem Whiteboard" rel="noreferrer noopener"
              class="whiteboard-custom-nav-link3">
              Problem melden
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'headercomp',
}
</script>

<style scoped>
.whiteboard-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.holder {
  width: 400px;
  height: 300px;
  position: relative;
}

.frame {
  width: 100%;
  height: 100vh;
}

.overlay {
  align-content: center;
  display: grid;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  color: #333;
  ;
}

.overlayContainer {
  text-align: center;
}

.overlayContainer>* {
  margin: 10px 0;
}


.btncopy {

  cursor: pointer;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #f7f7f7;
}

/* Nav bar */

.whiteboard-custom-nav-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.whiteboard-custom-nav-header {
  width: 100%;
  height: 72px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.whiteboard-custom-nav-image {
  height: 2rem;
}

.whiteboard-custom-nav-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.whiteboard-custom-nav-link {
  margin-right: var(--dl-space-space-twounits);
}

.whiteboard-custom-nav-link1 {
  margin-right: var(--dl-space-space-twounits);
}

.whiteboard-custom-nav-thq-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.whiteboard-custom-nav-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.whiteboard-custom-nav-help {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}

.whiteboard-custom-nav-dropdown-arrow {
  transition: 0.3s;
}

.whiteboard-custom-nav-icon {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}

.whiteboard-custom-nav-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}

.whiteboard-custom-nav-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.whiteboard-custom-nav-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.whiteboard-custom-nav-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.whiteboard-custom-nav-link2 {
  margin-right: var(--dl-space-space-twounits);
}

.whiteboard-custom-nav-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.whiteboard-custom-nav-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.whiteboard-custom-nav-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.whiteboard-custom-nav-link3 {
  margin-right: var(--dl-space-space-twounits);
}

.whiteboard-custom-nav-fullscreen {
  display: flex;
  flex-direction: row;
}

.whiteboard-custom-nav-icon2 {
  width: 24px;
  height: 24px;
}

.whiteboard-custom-nav-normal-mode {
  display: flex;
  flex-direction: row;
}

.whiteboard-custom-nav-icon4 {
  width: 24px;
  height: 24px;
}

@media(max-width: 991px) {
  .whiteboard-custom-nav-link {
    margin-right: var(--dl-space-space-halfunit);
  }

  .whiteboard-custom-nav-link1 {
    margin-right: var(--dl-space-space-halfunit);
  }

  .whiteboard-custom-nav-thq-dropdown {
    display: none;
  }

  .whiteboard-custom-nav-link2 {
    margin-right: var(--dl-space-space-halfunit);
  }

  .whiteboard-custom-nav-link3 {
    margin-right: var(--dl-space-space-halfunit);
  }
}

@media(max-width: 767px) {
  .whiteboard-custom-nav-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media(max-width: 479px) {
  .whiteboard-custom-nav-header {
    padding: var(--dl-space-space-unit);
  }

  .whiteboard-custom-nav-link {
    display: none;
  }

  .whiteboard-custom-nav-link1 {
    display: none;
  }

  .whiteboard-custom-nav-thq-dropdown {
    display: inline-block;
  }

  .whiteboard-custom-nav-help {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .whiteboard-custom-nav-dropdown-list {
    display: none;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #595959;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #595959 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>

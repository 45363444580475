import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import App from "./App.vue";
import router from "./router";
//import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import VueCompositionAPI from "@vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Vidle from 'v-idle'

// Vue.use(VueCompositionAPI);
Vue.use(Vidle)

// // Import Bootstrap an BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
// //import "./app.scss";

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
